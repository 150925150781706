import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

Vue.use(VueRouter)

/* webpackPrefetch: true */
/*
 * webpack magic commet, which will prefetch next page.
 * if we know that user is definitely going to that one page (for example If users is going to products page while in categories, we add comment to products)
 * this will add < link rel="prefetch" href="/products" />
 */

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/admin-users'
    },
    {
      path: '/admin-users',
      name: 'admin-users',
      component: () => import('@/views/AdminUser/AdminUsers.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/collections',
      name: 'collections',
      component: () => import('@/views/Collection/Collections.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/collection-requests',
      name: 'collection-requests',
      component: () => import('@/views/CollectionRequests/CollectionRequests.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/nft-requests',
      name: 'nft-requests',
      component: () => import('@/views/NFTRequests/NFTRequests.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/nfts',
      name: 'nfts',
      component: () => import('@/views/NFT/NFTs.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/minted-nfts',
      name: 'minted-nfts',
      component: () => import('@/views/NFT/MintedNFTs'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/nft/add',
      name: 'add-nft',
      component: () => import('@/views/NFT/AddNFT')
    },
    {
      path: '/authors',
      name: 'authors',
      component: () => import('@/views/Author/Authors.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/author-requests',
      name: 'author-requests',
      component: () => import('@/views/AuthorRequests/AuthorRequests.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/giveaway',
      name: 'giveaway',
      component: () => import('@/views/Giveaway/Giveaway.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackPrefetch: true */ '@/views/Login.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})

router.beforeEach((to, from, next) => {

  const loggedIn = localStorage.getItem('loggedIn')
  if (loggedIn !== 'undefined' && loggedIn && loggedIn === 'true') {
    store.state.user.loggedIn = true
    const userData = localStorage.getItem('userData')
    if (userData) {
      store.state.user.userData = JSON.parse(userData)
    }
  }

  if (to.meta.requiresAuth && !store.state.user.loggedIn) {
    return next('/login')
  }

  next()
})


// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
