export default {
    namespaced: true,
    state: {
        loggedIn: false,
        userData: null
    },
    getters: {
    },
    mutations: {
        SET_LOG_IN(state) {
            state.loggedIn = true
        },
        SET_LOG_OUT(state) {
            state.loggedIn = false
        },
        SET_USER_DATA(state, payload) {
            state.userData = JSON.parse(payload)
        },
        REMOVE_USER_DATA(state) {
            state.userData = null
        }
    },
    actions: {
        setLogin({ commit }) {
            commit('SET_LOG_IN')
            localStorage.setItem('loggedIn', 'true')
        },
        setLogout({ commit }) {
            commit('SET_LOG_OUT')
            commit('REMOVE_USER_DATA')
            localStorage.setItem('loggedIn', 'false')
            localStorage.removeItem('userData')
            
        },
        setUserData({ commit }, payload) {
            commit('SET_USER_DATA', JSON.stringify(payload))
            localStorage.setItem('userData', JSON.stringify(payload))
        }
    }
}
