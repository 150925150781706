import Vue from 'vue'
import store from '@/store'
import router from '@/router'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  timeout: 120 * 1000
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.response.use(function(response) {
    return response
}, function(error) {
    if (error.response.status === 401 || error.response.status === 403) {
        store.dispatch('user/setLogout')
        if (router.currentRoute.name !== 'login') {
            router.push('/login').then()
        }
    }
    return Promise.reject(error)
})


axiosIns.interceptors.request.use(
    config => {
        const jwt = localStorage.getItem('accessToken')
        if (jwt !== '') config.headers.common.Authorization = `Bearer ${jwt}`

        return config
    },
    error => Promise.reject(error)
)

axiosIns.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            if (router.currentRoute.name !== 'login') {
                router.push('/login').then()
            }
        }
        return Promise.reject(error)
    }
)

Vue.prototype.$http = axiosIns

export default axiosIns
