import Vue from 'vue'
const chains = {
    1: {
        name: 'Binance Smart Chain',
        shortName: 'BSC',
        logo: 'Binance_Logo.svg'
    },
    // 2: {
    //     name: 'SKALE Network',
    //     shortName: 'SKALE',
    //     logo: 'SKALE_Logo.png'
    // },
    3: {
        name: 'OKC',
        shortName: 'OKC',
        logo: 'OKC_Logo.png'
    }
}

Vue.prototype.$getChain = (chainId) => chains[chainId]
Vue.prototype.$chains = chains
export default chains